var lang = {
	// page addfund begin",
	back: "Back",
	add_fund: "Add fund",
	balance_payment_method_automatic: "Auto add your fund",
	balance_payment_method_automatic_des: "Our Control Panel supports automatic payment updates via channels: Binance, Skrill, Perfect Money, VietComBank and some channels do manual: USDT TRC20, Ethereum, Bitcoin. Please enter ONLY the CODE shown in RED below as in the illustration, your acocunt will be updated immediately when our account receives your payment.Note: Each service has different wallets, but you can transfer money from one wallet to another to use.",
	payment_addfund_choose_the_wallet_you_want_to_top_up: "Choose wallet for add fund",
	payment_method: "Method",
	payment_note: "Content/Note/Description/Message/Memo",
	payment_addfund_enter_the_note_as_image_below_view_type:"Enter note as image below",
	wallet: "Wallet",

	// page order begin",
	orders: "Orders",
	orders_help_action: "Hold and slide order from Right to Left to do some actions with this order",
	key_word: "Keyword",
	key_word_placeholder: "Enter keyword, order id, url, video id or profile id",
	status: "Status",
	all: "All",
	status_1: "Wait active",
	status_2: "Active",
	status_3: "Running",
	status_4: "Completed",
	status_5: "Cancelled",
	status_7: "Request cancel",
	status_8: "Pause",
	copy_id: "Copy ID",
	delete: "Delete",
	ordered: "order",
	success: "Success",
	request_had_been_success: 'Your request has been sent successfully, you will receive a response soon',
	request_had_been_failed: 'Request denied',
	request_sending: 'Request sending...',
	change_accesstoken: "Change Access token",
	change_accesstoken_guide: 'Go to Creator Studio "https://business.facebook.com/creatorstudio/home", view page source(Ctrl+U) and copy all html content (Ctrl+A and Ctrl+C), finaly paste to field Access token, system will be auto find and get it',
	cancel: "Cancel",
	send: "Send",
	failed: "Failed",
	accesstoken_updated: "Access token had been updated",
	accesstoken_updating: "Access token updating...",
	accesstoken_wrong: "Token wrong, please check.",
	select_action_for_order: "Choose action for order",
	copy_url: "Copy URL",
	copy_id_video: "Copy Video id",
	can_not_pause_this_order: "Can not pause this order",
	can_not_change_this_order: "Can not edit this order",
	pause: "Pause",
	can_not_pause_this_order: "Can not pause this order",
	order_not_active: "Order not active",
	resume: "Resume",
	request_cancel: "Request cancel",
	can_not_request_cancel_this_order: "Can not request this order",
	required: "Required",
	service_111:"600k minutes",
	service_68:"600k live minutes",
	service_60:"60k live minutes",

	// page payments begin",
	payments_history: "Payment history",
	your_payments: "Your payments",

	//page home
	intro_des: "Thank you for joining the AmaC Ecosystem with more than 300 Resellers, Agents worldwide, we specialize in providing Adbreaks related services.",
	intro: "Intro",
	orders_from_your_client: "Orders from your clients",
	your_clients: "Your clients",
	your_orders: "Your orders",
	you_spent: "Your spent",
	statistic_today_all: "Statistic today/All",
	manage_orders_here: "Manage your clients at here",
	discount: "Discount",
	price_list: "Price list",
	balance: "Balance",
	hi: "Hi",

	//page 404
	sorry: "Sorry",
	your_link_access_not_found: "Page not found",

	//page service
	service_list: "Services list",
	price_list_for_you: "Price list for you",

	//page promotion
	discount_code: "Discount code",
	expiry_date_until_end_of_day: "Expired until end of day",
	click_the_line_to_copy_discount_code: "Click the line to copy discount code",
	offer_for_you: "Discount for you",
	promotions_description: '{value} discount for all order of "{service}" service within {pack_time} since purchase.',
	promotions_hours: "hours",
	promotions_day: "day",
	promotions_days: "days",
	promotions_amount_max: "Max {view_remain} views.",
	promotions_description_package: "Give you disount {value} for all service.",
	promotions_description_package: 'Give you a {value} discount code for all order of "{service}" service.',
	promotions_description_min_price: "Min {min_price} / order",
	promotions_amount_remain: "Remain {view_remain} views",
	promotions_all: "all",
	promotions_free: "Free",

	//page contact
	contacts_contacts_info: "Contact info",
	contacts_email: "Email",
	contacts_phone_zalo_whatsapp_telegram: "Phone, Zalo, WhatsApp, Telegram",

	//page notifications
	notifications: "Notifications",
	your_notifications: "Your notifications",

	//page setting
	settings: "Settings",
	comming_soon: "Comming soon...",

	//page app
	sign_out: "Sign out",
	accounts: "Accounts",
	change_password: "Change password",
	change_email: "Change email",
	change_avatar: "Change avatar",
	place_order: "Place order",
	get_accesstoken: "Get access token Facebook",
	guide: "Guide",
	username: "Username",
	username_placeholder: "Your username",
	password: "Password",
	password_placeholder: "Your password",
	sign_in: "Sign in",
	sign_up: "Sign up",
	sign_in_help: "You can use account from AMAC site for sign in",
	home: "Home",
	new_order: "New order",
	transactions_history: "Transactions",
	contacts: "Contacts",
	close: "Close",
	title_600k: "Order 600k minutes from video lengh minimum 123 minutes",
	des_600k: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Video must public and video length have minimum 123 minutes',
	video_url: "Video url",
	video_url_placeholder: "Per link is per line",
	accesstoken: "Access token",
	accesstoken_placeholder: "Required to enter Access token here for get minutes from your page",
	note: "Note",
	note_placeholder: "Enter your note",
	discount_code: "Discount code",
	discount_code_placeholder: "Enter discount code",
	you_will_pay: "You will pay",
	for_this_order: "for this order",
	how_to_get_accesstoken: "How to get Access token",
	get_accesstoken_guide: 'Go to "https://business.facebook.com/business_locations", view page source(Ctrl+U) and copy all html content (Ctrl+A and Ctrl+C), finaly paste to field Access token, system will be auto find and get it',
	title_600k_live: "Order 600k live minutes",
	des_600k_live: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Video livestream must public and livestream from 1 to 8 hours',
	title_60k_live: "Order 60k live minutes",
	des_60k_live: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Video livestream must public and livestream from 1 to 8 hours',
	support_urls: "Support all link",
	please_create_token_api_for_use_this_app: "Please create token API for use this App!",
	failed_login_try_again: "Failed sign in, please try again!",
	enter_username_and_password_for_sign_up: "Please enter username and password in the box above for sign up",
	enter_username_for_sign_up: "Please enter username in the box above for sign up",
	enter_password_for_sign_up: "HPlease enter password in the box above for sign up",
	failed_sign_up_try_again: "Failed sign up, please try again!",
	video_uploaded_or_live_end: "Video uploaded or Live end",
	video_livestreaming: "Video livestreaming",
	url_wrong: 'Please enter correct URL',
	mount_wrong: "Please enter correct amount",
	view_type_wrong: "You not select view type",
	ordering: "Ordering...",
	order_success_you_can_place_other: "Ordered successful. You can continude enter new url for new order",
	loading: "Loading...",
	this_promotion_still_not_begin: "This promotion still not begin",
	this_promotion_had_been_expired: "This promotion had been expired",
	this_promotion_not_support_service: "This promotion not support service",
	this_promotion_not_for_you: "This promotion not for you",
	this_promotion_required_min: "This promotion required min",
	reduce: "Reduce",
	this_promotion_not_found: "This discount not found",
	accesstoken_can_not_detect: "Can not detect access token, you should get manual!",

	//file app.js
	copied: "Copied",
	error: "Error",
	can_not_copy: "Can not sopy",
	notifications_orders_created: 'Order #{main_id} by {userApply} had been created.',
	notifications_orders_restarted: 'Order #{main_id} by {userApply} had been restarted again.',
	notifications_orders_cancelled: 'Order #{main_id} by {userApply} had been cancelled.',
	notifications_orders_deleted: 'Order #{main_id} by {userApply} had been deleted.',
	notifications_orders_completed: 'Order #{main_id} by {userApply} had been completed.',
	notifications_orders_refundtimelive: 'Order #{main_id} by {userApply} not livestream full time.',
	notifications_orders_renew: 'Order #{main_id} in {service} had been auto created.',
	notifications_payments_payment: '{userApply} {price} into balance {service}. {userApply} pay for order #{main_id}.',
	notifications_payments_refund: '{userApply} {price} into balance {service}. {userApply} received refund for order #{main_id}.',
	notifications_payments_refundtimelive: '{userApply} {price}. {userApply} refund for order #{main_id} not livestream full time.',
	notifications_payments_addfund: '{userApply} {price} into balance {service}. {userApply} added fund via {payment_method}.',
	notifications_payments_receivefund: '{{userApply} {price} into balance {service}. {userApply} receive fund from {user_more} via {payment_method}.',
	notifications_payments_receive: '{userApply} {price} into balance {service}. {user_more} had been confirm payment via {payment_method}.',
	notifications_payments_sent: '{userApply} {price} into balance {service}. {userApply} sent to {user_more} via {payment_method}.',
	notifications_payments_transfer_from: '{userApply} {price} into balance {service}. {userApply} paid for the request to transfer money from {service_old} to {service_new}.',
	notifications_payments_transfer_to: '{userApply} {price} into balance {service}. {userApply} received payment for requesting transfer from {service_old} to {service_new}.',
	notifications_payments_transfer_pending: '{user_more} just create requesting transfer from {service_old} to {service_new}. {userApply} need confirm this transfer for {user_more} with amount {price}.',
	notifications_payments_payment_promotion: '{userApply} {price}. {userApply} pay for promotion #{main_id}.',
	notifications_promotions_buy: 'Promotion #{main_id} had bought by {userApply}.',
	notifications_payments_sell_promotion: '{userApply} {price}. {userApply} had sell promotion #{main_id}.',
	notifications_promotions_sell: 'Promotion #{main_id} had sell by {userApply} to {{user_more}}.',
	notifications_users_register: '{user_more} had been register an acocunt. {userApply} is manager for {user_more}.',
	notifications_promotions_reward: '{userApply} has been awarded promotion {promotion}.',
	reduce_note: "Use this discount, your video will be watched by account CLONE, if you want to your video watched by account VIA, please remove this discount!",
	payment_addfund_guide_for_not_auto_payment: "This method not automatic, so please send your code and a picture about your payment",
	contacts_facebook: "Facebook",
	get_accesstoken_guide_url: "https://business.facebook.com/business_locations",
	go_to_get_access_token: "Go to get Access token",
	or: "or",
	service: "Service",
	minutes_done: "Minutes viewed currently",
	live_minutes_done: "Live minutes viewed currently",
	click_the_line_to_apply_discount_code: "Click to discount code for apply",
	js_access_token: `function abc(n,t,e){if(!n||""==n)return"";var r=n.toString().indexOf(t);if(0>r)return"";r+=t.length;var o=n.indexOf(e,r),i=n.substring(r,o);return i}var al="EAAGN",tk=al+abc(document.body.innerHTML,al,'"');copy(tk),tk.indexOf(al)>-1?console.log("Copied Access token to clipboard"):console.log("Access token not found");`,
	how_to_get_accesstoken_console: 'Get Access token at console',
	get_accesstoken_guide_console: 'Click copy code console below -> go to "https://business.facebook.com/business_locations" -> F12 or Right click and Inspect(Inspect Element) -> click tab Console -> Ctrl+V (paste code console) -> Enter for done',
	console: 'Console',
	service_11: 'Livestream viewer',
	title_livestream_viewer: 'Book viewer for your livestream',
	video_livestream_url:'Id video url video livestream',
	video_livestream_url_placeholder: 'Ex: Id video: 887123912387213',
	amount_viewer: 'Number viewer',
	amount_viewer_placeholder: 'Enter minimum 10',
	num_minutes: 'Number minutes',
	minutes: 'minutes',
	general_1_viewer_on_30_minutes: '1 viewer/1 minute',
	stop_and_refund: 'Stop and refund time remain',
	can_not_stop_this_order: 'Can not stop this order',
	can_not_resume_this_order: 'Can not resume this order',
	live_view_amount_min: 'Enter amount viewer minimum is 10',
	live_view_minute_min: 'Enter minutes minimum is 30',
	this_service_not_active: 'This service not active with you, please contact to Manager for active',
	service_biggest: 'Biggest in market',
	service_cheapest: 'Cheapest in the world',
	service_stable: 'Stable than all panel',
	service_professionally: 'System Professionally',
	service_enthusiastic_support: 'Enthusiastic Support',
	intro_live_des: "Thank you for joining the AmaC Ecosystem with more than 300 Resellers, Agents worldwide, we specialize in providing viewer for your livestream",
	remain: 'Remain',
	ran: 'Ran',
	origin: 'Origin',
	book: 'book',
	viewers: 'viewers',
	view_on_facebook: 'View on Facebook',
	chat_with_manager: 'Chat with supporter',
	chat_with_manager_done: 'You seen!',
	chat_with_manager_new: 'You have new message',
	show_new_chat_only: 'Show only the orders have new chat',
	available: 'Available',
	show_have_chat_only: 'Show the orders had seen chat',
	show_not_enough_viewers: 'Show the orders not enough viewers',
	amount_viewer_not_enough: 'System auto detected number viewers not enough and fill in below, but you can check and edit.',
	at: 'at',
	still_not_get_viewers: 'Still not get log viewers',
	have: 'Have',
	amount_viewer_more: 'Number viewers push more',
	chat_with_client: 'Chat with client',
	dark_theme: 'Dark Mode',
	light_theme: 'Light theme',
	amount_price: 'Enter price want to add fund',
	scan_qr_code: 'Scan QR code for fast payment',
	cookie: 'Cookie',
	cookie_placeholder: 'Cookie have "c_user" and "xs"',
	change_cookie: 'Change Cookie',
	get_cookie_guide: 'Go to Facebook home, Press F12 or right mouse and select Inspect, click to Network, see the requests have banzai then click to it and go to Header, scroll to bottom to find cookie and copy them',
	cookie_wrong: 'Wrong cookie',
	cookie_updating: 'Cookie is updating',
	cookie_updated: 'Cookie had been updated',
	price_wait: 'How much {method} that you will send (not include fee)?',
	price_wait_placeholder: 'If you pay total is 10 {method}, and 1 {method} for fee, please enter 9 in there:',
	title_price_wait: 'Auto add fund for {method}',
	payment_addfund_guide_for_crypto: 'Follow these steps below for automatic add fund {method}',
	crypto_step_1: 'Step 1',
	crypto_step_1_text: 'Fill in 2 fields',
	crypto_step_2: 'Step 2',
	crypto_step_2_text: 'Make payment {price} {method} to account id: {id}',
	crypto_step_3: 'Step 3',
	crypto_step_3_text: 'After you paid,Please wait about 10 minutes, the system will check and auto add your fund',
	please_enter_correct_price: 'Please enter correct price',
	crypto_step_1_title: 'Register payment first',
	registering_payment: 'Registering payment',
	registered_payment: 'Registered payment',
	cookie_wrong: 'Cookie wrong or die',
	cookie_checking: 'Checking your cookie ...',
	cookie_success: 'Cookie worked',
	something_went_wrong: 'Something went wrong',
	how_to_get_cookie: 'How to get cookie',
	see_video: 'Click to see video',
	change_for_all_order_same_cookie: 'Change for all order have same cookie?',
	viewers_not_available: 'Viewers not available',
	viewers_available_updating: 'Checking available Viewers...',
	repassword: 'Re-password',
	repassword_placeholder: 'Your password',
	email: 'Email',
	email_placeholder: 'Your email for restore password',
	password_not_match: 'Password not match',
	enter_email_for_sign_up: 'Enter email for sign up',
	signin_button: 'Already have an account?',
	signup_button: "Don't have an account?",
	enter_password_to_access: "enter password of your username at website {url} to continude",
	error_alias_error_other: 'Cookie die',
	error_alias_url_die: 'Video die',
	error_alias_cookie_die: 'Cookie die',
	error_alias_unpublished: 'Page unpublished',
	error_alias_wait: 'Checking video',
	error_alias_not_enough_time: 'Not enough 3 hours',
	error_alias_not_public: 'Video unpublic',
	error_alias_none_permission: 'Cookie none permission',
	password_must_be_least_6_characters: 'Passwords must be at least 6 characters',
	user_username_exists: 'Username already exists',
	user_email_exists: 'Email already exists',
	user_login_failed: 'Login failed',
	user_not_exists: 'Username not exists',
	password_not_match: 'Wrong password',
	note_cookie_live: '<span style="color: #ff6b22; font-weight: bold;">TIP get Cookie</span>: Cookies should be taken from a different facebook account than the one used to broadcast the livestream, because cookies are easy to checkpoint, so if you share them, when your account get checkpoint, your livestream will be stopped , if video not enough 123 minutes your order can not continue.',
	error_alias_is_crosspost_video: 'Is Crosspost video',
	go_to_site_vip: 'Go to site VIP if full viewers',
	discount_enter: 'Enter discount',
	discount_enter_placeholder: 'Enter discount you know',
	discount_active: 'Active',
	promotion_apply_success: 'Apply successful',
	error_alias_promotion_not_found: 'This discount not found',
	error_alias_promotion_apply_error: 'Apply error, please try again',
	error_alias_not_enough_fund: 'This discount required In {days} days you must add fund minimum {money}. But in this time, you had added fund {my_fund} only. You should add more {morefund} for active this discount',
	error_alias_something_went_wrong: 'Something went wrong',
	balance_before_order: 'Balance before order: ',
	service_15:"Reels Plays (not count money)",
	title_100k_reels: 'Order reels plays',
	des_100k_reels: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Reels video must public and video length have minimum 10 seconds',
	warning: 'Warning',
	service_not_active_with_you: 'Service not active with you now. Please click button "Active" below for active this service if it available',
	activating: 'Activating...',
	activated: 'Activated',
	amount_reels: 'Amount (default 100k, you can change any 500-10M)',
	amount_reels_placeholder: 'Enter amount play you want, min 500',
	active_service: 'Active',
	service_15_root:"reels play",
	cookie_required: "optional",
	video_reels_plays: "Reels plays",
	service_16: "Reels Bonus Plays (count money)",
	title_reels_bonus: 'Order reels bonus plays count money',
	des_reels_bonus: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Reels video must public and video length have minimum 20 seconds',
	service_16_root:"reels bonus plays",
	general_1_view: '1 view',
	general_1_reel: '1 reel',
	follow_page: 'Follow Page',
	service_115: "Follow Page Classic",
	service_116: "Follow Page Profile (New Pages Experience)",
	title_follow_classic: "Follow Page Classic",
	des_follow_classic: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Your page must public',
	page_url: 'Page Id or Url',
	page_url_placeholder: "Ex: Page Id: 100063723872591",
	amount_follow: "Amount (default 10k, you can change any 500-100k)",
	amount_follow_placeholder: "Enter amount follow you want, min 500",
	support_urls_page: "Support all format, however should be page id",
	title_follow_profile: "Follow Page Profile (New Pages Experience)",
	des_follow_profile: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Your page must public and have at least 1 Post or Photo or Video in this page',
	general_1_follow: '1 follow',
	service_115_root: 'follows',
	service_116_root: 'follows',
	title_60k: "Order 60k minutes from video lengh minimum 123 minutes",
	des_60k: '<span style="color: #ff6b22; font-weight: bold;">Note</span>: Video must public and video length have minimum 123 minutes',
	service_118: "60k minutes",
	amount_views: "Amount views (default 500)",
	amount_views_placeholder: "Enter amount views you want, min 500",
	service_117: "Post Reactions",
	amount_reaction_min: "Amount must grather than",
	title_post_reaction: "Post Reactions",
	des_post_reaction: "Increase Post Reaction: Like, Love, Haha, Wow, Angry, Sad",
	amount_reaction: "Amount (default 50, you can change any 50-100k)",
	amount_reaction_placeholder: "Enter amount reaction you want, min 50",
	reaction_type: "Reaction Types",
	post_url: "Full url of post, photo, video or video livestream. Per url per line",
	post_url_placeholder: "Urls must begin by https://www.facebook.com/......",
	support_urls_post: "Support all format begin by https://www.facebook.com/......, however should be post id",
	service_117_root: 'reactions',
	general_1_reaction: "1 reaction",
	service_201: 'Tiktok Livestream viewer',
	tiktok_title_livestream_viewer: 'Book viewer for your livestream Tiktok',
	tiktok_video_livestream_url:'Your url profile livestream',
	tiktok_video_livestream_url_placeholder: 'Ex: https://www.tiktok.com/@amazingcpanel/live',

}
export default lang;