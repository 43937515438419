var site = {
  name: "Watch Time Facebook",
  supplier: '5ce8260f4b8f960e5c0d09f0',
  lang_default: 'us',
  managerSite: 'https://fbvideoview.com/',
  // service_alias: 'minutes',
  // services: [
  // "63e645ec5df0bfcbd0c47c6a",//60k minutes,
  // "60487de89f59b726488ebec3","5e0c5e84fda7621b5db9cd2b","6058ad61163ee61790e3143d","62f9deeb23e90ce94a0b2ba7",
  // "62fc5c74b20ce151b557512a",//reel local
  // "63a995bc60b0b575377a7e81",
  // "63c409744243de38e2f4d124",//follow class
  // "63c7a20b4243de38e2f627e1",//follow pro5
  // "63cfeda22198f0df3483a880",//reaction
  // ],
  // service_alias: 'live',
  // service_alias: 'live_vip',
  // services: ["5d4af99344156a12f8173de5"],
  service_alias: 'live_tiktok',
  services: ["64de11aae30c95146e9cd73f"],
  paymentData: {
        'Vietcombank' : {
          'status': true,
          'selected': true,//set default show
          'id': '0381000438756',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Vietcombank',
          'min': 2.5,
          'auto': true,
        },
        'Momo' : {
          'status': true,
          'id': '0765152065',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Momo',
          'min': 2.5,
          'auto': true,
        },
        'Paypal' : {
          'status': false,
          'id': 'adbreaksvietnam@gmail.com',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Paypal',
          'min': 5,
          'auto': true,
        },
        'Payoneer' : {
          'status': false,
          // 'selected': true,//set default show
          'id': 'vanthinh291@gmail.com',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Payoneer',
          'min': 5,
          'auto': true,
        },
        'PerfectMoney' : {
          'status': true,
          'selected': false,
          'id': 'U15258486',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Perfect Money',
          'min': 5,
          'auto': true,
        },
        'Binance' : {
          'status': true,
          'selected': false,//set default show
          'id': '397734662',
          'name': 'vanthinh291@gmail.com',
          'bank_name': 'Binance (USDT TRC20)',
          'min': 5,
          'auto': true,
        },
        'Skrill' : {
          'status': true,
          'id': 'vanthinh291@gmail.com',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Skrill',
          'min': 5,
          'auto': true,
        },
        'USDTTRC20' : {
          'status': true,
          'selected': false,//set default show
          'id': 'TGHVKViaW5nea2SZe2dNcjDW961a7qUrPr',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'USDT TRC20',
          'min': 5,
          'auto': true,
        },
        'Techcombank' : {
          'status': false,
          'id': '19034287915015',
          'name': 'Cao Thi Kim Phuong',
          'bank_name': 'Techcombank',
          'min': 5,
          'auto': true,
        },
        'Bitcoin' : {
          'status': true,
          'selected': false,//set default show
          'id': '33GsV9uo6QvMv38uK9tY3TNm1n2JvjkDNM',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Bitcoin',
          'min': 5,
          'auto': true,
        },
        'Ethereum' : {
          'status': true,
          'selected': false,//set default show
          'id': '0x5Dc519eDf094BfEeD2c94cCfae0A47025efb4A99',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Ethereum TRC20',
          'min': 5,
          'auto': true,
        },
    },
    contacts: {
        'phone': {
          'id': '+84975308144',
          'name': 'Phone, Zalo, WhatsApp, Telegram:',
        },
        'email': {
          'id': 'amazingcpanel@gmail.com',
          'name': 'Email:',
        },
    },
}
export default site;