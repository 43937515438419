<template>
  <f7-page name="orders"  @page:init="onPageInit"
  ptr @ptr:refresh="loadRefresh"
  @init="loadMore"
    infinite
    :infinite-distance="500"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
    @load="onPageInit"
  >
    <f7-navbar>
      <f7-nav-left>
      <f7-button 
       icon-f7="slider_horizontal_3"  @click="toggleFilter()" style="width: 50px;"></f7-button>
    </f7-nav-left>
    <f7-nav-title v-html="$root.setLang('orders')+$root.getViewerAvailable()">
    </f7-nav-title>
      <f7-nav-right>
      <f7-button 
      :class="{button_reload:is_loading==true}"
       icon-f7="arrow_2_circlepath"  @click="loadRefresh()" style="width: 50px;"></f7-button>
    </f7-nav-right>
    </f7-navbar>
    <!-- <f7-block-title style="margin-top:10px;">
      <f7-button 
       icon-f7="slider_horizontal_3"  @click="show_filter=!show_filter" style="width: 50px;float: left;"></f7-button>
      <f7-button 
      :class="{button_reload:is_loading==true}"
       icon-f7="arrow_2_circlepath"  @click="loadRefresh()" style="width: 50px;float: right;"></f7-button>
    </f7-block-title> -->
    
    <f7-block-header>{{$root.setLang('orders_help_action')}}</f7-block-header>
      <f7-row  v-if="show_filter">
        <f7-col width="100" small="100" large="33">
<f7-list inline-labels no-hairlines-md style="margin: 0;">
    <f7-list-item
      checkbox
      :checked="show_new_chat"
      :title="$root.setLang('show_new_chat_only')"
      @change="changeNewchat();"
    ></f7-list-item>
  </f7-list>
        </f7-col>
        <f7-col width="100" small="100" large="33">
          <f7-list inline-labels no-hairlines-md style="margin: 0;">
          <f7-list-item
      checkbox
      :checked="show_have_chat"
      :title="$root.setLang('show_have_chat_only')"
      @change="changeHavechat();"
    ></f7-list-item>
    
  </f7-list>
        </f7-col>
        <f7-col width="100" small="100" large="33">
          <f7-list inline-labels no-hairlines-md style="margin: 0;">
          <f7-list-item
      checkbox
      :checked="not_enough_viewers"
      :title="$root.setLang('show_not_enough_viewers')"
      @change="changeNotEnough();"
    ></f7-list-item>
    
  </f7-list>
        </f7-col>
      </f7-row>
    

      <form id="formFilter" style="margin-top: 20px;">
        <f7-row  v-if="show_filter">
      <f7-col width="100" small="100" large="50">
    <f7-list inline-labels no-hairlines-md  style="margin: 0;">
        <f7-list-input
          :label="$root.setLang('status')"
          type="select"
          name="status"
          :value="status"
          v-on:change="foo"
          :placeholder="$root.setLang('all')"
        ><option value="-1">{{$root.setLang('all')}}</option>
          <option value="1">{{$root.setLang('status_1')}}</option>
          <option value="2">{{$root.setLang('status_2')}}</option>
          <option value="3">{{$root.setLang('status_3')}}</option>
          <option value="4">{{$root.setLang('status_4')}}</option>
          <option value="5">{{$root.setLang('status_5')}}</option>
          <option value="7">{{$root.setLang('status_7')}}</option>
          <option value="8">{{$root.setLang('status_8')}}</option>
        </f7-list-input>
    </f7-list>
      </f7-col>

      <f7-col width="100" small="100" large="50">
    <f7-list inline-labels no-hairlines-md  style="margin: 0;">
        <f7-list-input
          :label="$root.setLang('key_word')"
          type="text"
          name="key"
          :value="key"
          :placeholder="$root.setLang('key_word_placeholder')"
          @input="foo"
          clear-button
        >
        </f7-list-input>
    </f7-list>
      </f7-col>
  </f7-row>
      </form>
    <f7-block-header :color="($root.darkTheme)?'white':'black'">{{$root.setLang('have') + ' '}}<span style="font-weight: bold;">{{total_order}}</span>{{' ' +$root.setLang('orders')}}</f7-block-header>
    <f7-list media-list>

    <!-- <f7-list-item
      link="#"
      header="hihi"
      title="Yellow Submarine"
      after="$15"
      subtitle="Beatles"
      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
    >
      <img slot="media" src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg" width="80" />
    </f7-list-item> -->
    <!-- :header="`${order.user.username} - ${order.created}`" -->
    <!-- 
        :link="`/videovieworders/${order._id}/`" -->

      <!-- <f7-list-item
      swipeout
        v-for="(order, index) in $f7.data.videovieworders"
        :key="index"
        :badge="$f7.data.setPrice(order.price)"
        badge-color="deeporange"
        :header1="`${order.user.username} - ${order.created}`"
        v-if="$f7.data.videovieworders.length > 0"
        > -->
        <f7-list-item
      swipeout
        v-for="(order, index) in $f7.data.videovieworders"
        :key="index"
        :header1="`${order.user.username} - ${order.created}`"
        v-if="$f7.data.videovieworders.length > 0"
        >
        <f7-swipeout-actions right>
          <f7-swipeout-button @click="more(order)">...</f7-swipeout-button>
          <f7-swipeout-button color="blue"  v-clipboard:copy="order._id"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError">{{$root.setLang('copy_id')}}</f7-swipeout-button>
          <f7-swipeout-button  @click="doOrder(order, 'delete')" color="red">Xoá</f7-swipeout-button>
        </f7-swipeout-actions>
        <f7-link slot="title" :href="order.permalink_url" target="_blank" external>{{order.video_id}} 
          <f7-icon v-if="order.status == 3" f7="eye" size="14px" color="white" style="-webkit-font-smoothing: antialiased;
align-items: center;
animation-direction: alternate;
animation-duration: 0.7s;
animation-iteration-count: infinite;
animation-name: eeh53dpm-B;
animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
background-color: rgb(240, 40, 74);
border-bottom-left-radius: 4px;
border-bottom-right-radius: 4px;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
box-sizing: border-box;
color: rgb(28, 30, 33);
direction: ltr;
display: flex;
font-size: 12px;
height: 18px;
line-height: 16.079999923706055px;
padding-bottom: 0px;
padding-left: 3px;
padding-right: 6px;
padding-top: 0px;
width: 60px;
color: #fff;
background-color: rgb(149, 148, 148);
background-color: rgb(96, 96, 96);
border-radius: 50%;
width: 20px;
display: inline-flex;
margin-left: 12px;
margin-right: 5px;
/*background-color: rgb(133, 130, 130);*/
"></f7-icon>
<div v-if="order.status == 3 && !checkViewer(order)" class="icon-tooltip-viewer" :style="'color: '+(($root.darkTheme)?'#ff3b30':'#f4073b')+';text-align: center;font-weight: bold;font-size: 13px;display: initial;'">{{getCurentViewer(order)}}</div>
<div v-if="order.status == 3 && checkViewer(order)" class="icon-tooltip-viewer" :style="'color: '+(($root.darkTheme)?'#5ac8fa':'#004dff')+';text-align: center;font-weight: bold;font-size: 13px;display: initial;'">{{getCurentViewer(order)}}</div></f7-link>
        <!-- <div slot="header">{{order.user.username}}<span style="color: #ff6b22;"> | </span>{{(order.service_id)?order.service_id.name:""}}<span style="color: #ff6b22;"> | </span>{{getHeader(order)}}</div> -->
        <div slot="header" v-if="$f7.data.user.roles.indexOf('admin') != -1" >{{order.user.username}} {{$root.setLang('book')}} {{order.amount}} {{$root.setLang('viewers')}}/{{order.num_minutes}} {{$root.setLang('minutes')}}. {{$root.setLang('origin')}} {{order.start_count}} {{$root.setLang('viewers')}}</div>
        <div slot="header" v-if="$f7.data.user.roles.indexOf('admin') == -1" >{{order.user.username}} {{$root.setLang('book')}} {{order.amount}} {{$root.setLang('viewers')}}/{{order.num_minutes}} {{$root.setLang('minutes')}}. {{$root.setLang('origin')}} {{order.start_count}} {{$root.setLang('viewers')}}</div>
        <!-- <img slot="media" :src="`https://graph.facebook.com/${order.profile_id}/picture?type=large`" width="36" style="border-radius: 50%;border: 1px solid #a9a9a9;" /> -->
        <div slot="media" style="">
          <img :src="(order.profile_id != 0 && order.profile_id != '0')?`https://graph.facebook.com/${order.profile_id}/picture?type=large`:`/static/images/viewer_livestream2.ico`" width="36" style="border-radius: 50%;border: 1px solid #a9a9a9;margin-top: 6px;" /> 
        </div>
        <!-- <div slot="inner"><f7-badge color="teal">{{statusOrder(order.status)}}</f7-badge></div> -->
        <!-- <div slot="inner"><f7-badge color="teal">{{statusOrder(order.status)}}</f7-badge></div> -->
        <div slot="subtitle">
<!-- <f7-chip :text="order.amount" ></f7-chip> -->
<!-- <f7-chip outline ><f7-icon slot="media" ios="f7:chevron_right" aurora="f7:chevron_right" md="f7:chevron_right" color="gray"></f7-icon></f7-chip> -->
<f7-chip :text="statusOrderNew(order)" :media-bg-color="statusOrderColor(order)">
      <template #media>
        <f7-icon ios="f7:checkmark_seal" aurora="f7:checkmark_seal" md="material:checkmark_seal"></f7-icon>
      </template>
    </f7-chip>
<!-- <f7-chip outline  :text="statusOrderNew(order)" ><f7-icon slot="media" ios="f7:chevron_right" aurora="f7:chevron_right" md="f7:chevron_right" color="gray"></f7-icon></f7-chip> -->
<!-- <f7-chip outline :text="`${order.start_count}`">
  <f7-icon slot="media" ios="f7:chevron_right" aurora="f7:chevron_right" md="f7:chevron_right" color="gray"></f7-icon>
</f7-chip>
<f7-chip outline :text="`${order.delivery_count}`">
  <f7-icon slot="media" ios="f7:chevron_right_2" aurora="f7:chevron_right_2" md="f7:chevron_right_2" color="gray"></f7-icon>
</f7-chip> -->
        <f7-chip outline v-if="([3,8].indexOf(order.status) > -1 && order.service_id.id == 201)">
          <div slot="text">
            {{$root.setLang('remain')}} {{order.num_minutes - parseInt((Date.now()/1000-order.start_time)/60)}} {{$root.setLang('minutes')}}
          </div>
        </f7-chip>
        <f7-chip outline v-if="([4,7].indexOf(order.status) > -1 && order.service_id.id == 201)">
          <div slot="text">
            {{$root.setLang('ran')}} {{parseInt(order.spent_time)}} {{$root.setLang('minutes')}}
          </div>
        </f7-chip>
        <f7-chip outline v-if="([1,2,5].indexOf(order.status) == -1 && order.service_id.id == 111)">
          <div slot="text">
            {{order.start_count}}<f7-icon slot="media" ios="f7:arrow_right" aurora="f7:arrow_right" md="f7:arrow_right" style="color:#a9a9a9;font-size: 18px;" ></f7-icon>{{order.delivery_count}}
          </div>
        </f7-chip>
        <f7-chip outline v-if="([1,2,5].indexOf(order.status) == -1 && (order.service_id.id == 68 || order.service_id.id == 60))">
          <div slot="text">
            {{order.start_count}}<f7-icon slot="media" ios="f7:arrow_right" aurora="f7:arrow_right" md="f7:arrow_right" style="color:#a9a9a9;font-size: 18px;" ></f7-icon>{{Math.floor(order.minutes_view_count/1000)}}k
          </div>
        </f7-chip>
        
        <f7-link :href="'/message/'+order._id+'/?data='+getDataMessage(order)" class="" style="display: inline-block;">
          <div v-if="order.user._id==$f7.data.user._id">
            <f7-icon v-if="order.notification_admin==0" ios="f7:bubble_left" aurora="f7:bubble_left" md="f7:bubble_left" :style="'font-size: 24px;color: #8b8989;'" class="icon-tooltip-chat-with-manager"></f7-icon>
            <f7-icon v-if="order.notification_admin==1" ios="f7:bubble_left_bubble_right_fill" aurora="f7:bubble_left_bubble_right_fill" md="f7:bubble_left_bubble_right_fill" :style="'font-size: 24px;color: #009688;'" class="icon-tooltip-chat-with-manager-done"></f7-icon>
            <f7-icon v-if="order.notification_admin==2" ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="f7:ellipses_bubble_fill" :style="'font-size: 24px;color: #ff2d55;'" class="icon-tooltip-chat-with-manager-new"></f7-icon>
          </div>
          <div v-if="order.user._id!=$f7.data.user._id">
            <f7-icon v-if="order.notification_user==0" ios="f7:bubble_left" aurora="f7:bubble_left" md="f7:bubble_left" :style="'font-size: 24px;color: #8b8989;'" class="icon-tooltip-chat-with-manager"></f7-icon>
            <f7-icon v-if="order.notification_user==1" ios="f7:bubble_left_bubble_right_fill" aurora="f7:bubble_left_bubble_right_fill" md="f7:bubble_left_bubble_right_fill" :style="'font-size: 24px;color: #009688;'" class="icon-tooltip-chat-with-manager-done"></f7-icon>
            <f7-icon v-if="order.notification_user==2" ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="f7:ellipses_bubble_fill" :style="'font-size: 24px;color: #ff2d55;'" class="icon-tooltip-chat-with-manager-new"></f7-icon>
          </div>
        </f7-link>

            
        <f7-button style="display: inline-block;" v-if="order.status == 3 && $f7.data.user.roles.indexOf('admin') > -1 && order.user.roles.indexOf('admin') == -1" @click="bupSupport(order)">
          <f7-icon ios="f7:wrench_fill" aurora="f7:wrench_fill" md="f7:wrench_fill" :style="'font-size: 24px;color: #ff2d55;'" class="icon-tooltip-bup_more"></f7-icon>
          
        </f7-button>
          <!-- <f7-badge color="green">{{order.amount}}</f7-badge> -->
        </div>
        <div slot="footer" v-if="order.last_message && order.last_message.text && order.last_message.text != '' && ((order.user._id!=$f7.data.user._id && order.notification_user==2) || (order.user._id==$f7.data.user._id && order.notification_admin==2))" class="message message-received message-first message-appear-from-bottom" style="display: inline-block;margin-top: 0;margin-bottom: 0;margin-left: 0;"><div class="message-content"><div class="message-bubble"><div class="message-text"><span class="">{{(order.last_message.text)}}</span></div></div></div></div>
        <!-- <div slot="after-title">&nbsp;|&nbsp;<span style="font-weight: bold;">{{order.amount}}</span></div> -->
        <div slot="after-title" style="position: absolute;right: -3px;top: -20px;">
          <f7-button @click="more(order)" icon-f7 ="ellipsis_vertical" icon-size="22px"></f7-button>
        </div>
        <div slot="after-title" style="position: absolute;
right: 10px;
top: 8px;"><span style="color: #ff9500; font-size:16px;">{{$root.setPrice(order.price)}}</span></div>
        <div slot="after-title" style="position: absolute;
right: 5px;
top: 28px;"><span style="font-size:11px;">{{getHeader(order)}}</span></div>
<div slot="after-title" style="position: absolute;right: 5px;top: 40px;"><span style="font-size:8px;">{{getServer(order)}}</span></div>
        <div slot="media" class="is_livestream_now_order" v-if="order.video_type == 2 && order.video_id != '' && order.start_count == 0 && (order.status == 1 || order.status == 2 || order.status == 3)"></div>
        <!-- <div slot="root-start">Root Start</div>
      <div slot="root">Root End</div>
      <div slot="content-start">Content Start</div>
      <div slot="content">Content End</div>
      <div slot="media-start">Media Start</div>
      <div slot="media">Media</div>
      <span slot="after-start">After Start</span>
      <span slot="after">After End</span>
      <div slot="inner-start">Inner Start</div>
      <div slot="inner">Inner End</div>
      <div slot="before-title">Before Title</div>
      <div slot="after-title">After Title</div> -->
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        orders: [],
        page: 2,
        order_swipe: {},
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        key: "",
        status: -1,
        datebegin: '',
        dateend: '',
        allowInfinite: true,
        showPreloader: true,
        started_page: false,
        is_loading: false,
        show_filter: false,
        your_balance: [],
        service_selected: '*',
        show_new_chat: false,
        show_have_chat: false,
        not_enough_viewers: false,
        total_order: 0,
        filter_json: {},
      };
    },
    methods: {
      foo(){
        const self = this;
        const app = self.$f7;
        var order = app.form.convertToData('#formFilter');
        if(order.key != self.key || order.status != self.status){
          self.key = order.key;
          self.status = order.status;
          self.service_selected = order.service_selected;
          self.filter_json.key = self.key;
          self.filter_json.status = self.status;
          app.form.storeFormData('filter_json', self.filter_json);
          self.loadRefresh();
        }
        
        // console.log("order",order)
      },
      queryForKeywords(event) {
        const self = this;
       const value = event.target.value
        self.keywords = value
           console.log('you are enter some key!!');
    },
    getDataMessage(order){
      delete order.log_v1;
      delete order.log_v2;
      delete order.log_v3;
      delete order.log_v4;
      // delete order.url;
      
      if(order.url.indexOf('&') > -1){
        if(order.permalink_url.indexOf('&') == -1){
          order.url = order.permalink_url;
        }else{
          delete order.url;
        }
      }
      return JSON.stringify(order);
    },
      toggleFilter(){
        const self = this;
        var app = self.$f7;
        self.show_filter =! self.show_filter;
        self.filter_json.show_filter = self.show_filter;
        app.form.storeFormData('filter_json', self.filter_json);
      },
      changeNewchat(){
        const self = this;
        var app = self.$f7;
        self.show_new_chat = !self.show_new_chat;
        if(self.show_new_chat){
          self.show_have_chat = false;
        }
        self.filter_json.show_new_chat = self.show_new_chat;
        self.filter_json.show_have_chat = self.show_have_chat;
        app.form.storeFormData('filter_json', self.filter_json);
        // console.log(self.show_new_chat)
        self.loadRefresh();
      },
      changeHavechat(){
        const self = this;
        var app = self.$f7;
        self.show_have_chat = !self.show_have_chat;
        if(self.show_have_chat){
          self.show_new_chat = false;
        }
        self.filter_json.show_new_chat = self.show_new_chat;
        self.filter_json.show_have_chat = self.show_have_chat;
        app.form.storeFormData('filter_json', self.filter_json);
        // console.log(self.show_new_chat)
        self.loadRefresh();
      },
      changeNotEnough(){
        const self = this;
        var app = self.$f7;
        self.not_enough_viewers = !self.not_enough_viewers;
        self.filter_json.not_enough_viewers = self.not_enough_viewers;
        app.form.storeFormData('filter_json', self.filter_json);
        self.loadRefresh();
      },
      loadBalances(callback) {
        const self = this;
        var app = self.$f7;
        try{
          var your_balance = app.data.userbalance.balances;
          var your_balance_new = [];
          for (var i = 0; i < your_balance.length; i++) {
            if(self.$root.services.indexOf(your_balance[i].service_id._id) != -1){
              your_balance_new.push(your_balance[i])
            }
          }
          self.your_balance = your_balance_new;
        }catch(e){
          setTimeout(function(){
            self.loadBalances(callback)
          }, 200)
        }
      },
      getHeader(order){
        const self = this;
        var d = new Date(order.created);
        return self.formatDate(d);//+' by '+ order.user.username
      },
      getServer(order){
        const self = this;
        if(order.server_cron)
        return 'SV'+self.$root.getString(order.server_cron,'//sv','.');
        return '';
      },
      getService(order){
        const self = this;
        if(order.service_id.id == 111){
          return self.$root.setLang('service_111');
        }else if(order.service_id.id == 68){
          return self.$root.setLang('service_68');
        }else if(order.service_id.id == 60){
          return self.$root.setLang('service_60');
        }
      },
      getMinutes(order){
        if(order.service_id.id == 111){
          return Math.floor(order.minutes_view_count/1000)+'k';
        }else if(order.service_id.id == 68){
          return Math.floor(order.minutes_view_count/1000)+'k';
        }else if(order.service_id.id == 60){
          return Math.floor(order.minutes_view_count/1000)+'k';
        }
        
      },
      getLiveMinutes(order){
        if(order.service_id.id == 68){
          return Math.floor(order.minutes_live_count/1000)+'k';
        }else if(order.service_id.id == 60){
          return Math.floor(order.minutes_live_count/1000)+'k';
        }
        
      },
      getMinutesStart(order){
        if(order.service_id.id == 111){
          return Math.floor(order.minutes_view_count_first/1000)+'k';
        }else if(order.service_id.id == 68){
          return Math.floor(order.minutes_view_count_first/1000)+'k';
        }else if(order.service_id.id == 60){
          return Math.floor(order.minutes_view_count_first/1000)+'k';
        }
        
      },
      formatDate(date) {
        // var monthNames = [
        //   "Jan", "Feb", "Mar",
        //   "Apr", "May", "Jun", "Jul",
        //   "Aug", "Sep", "Oct",
        //   "Nov", "Dec"
        // ];
        var monthNames = [
          "1", "2", "3",
          "4", "5", "6", "7",
          "8", "9", "10",
          "11", "12"
        ];

        var minute = date.getMinutes();
        if(minute < 10){
          minute = '0'+minute
        }
        var hour = date.getHours();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        // return  hour + ':' +minute + ' ' +  monthNames[monthIndex]+ ' ' +day ;
        return  day + '/' +  monthNames[monthIndex] + ' '+hour + ':' +minute ;
      },
      formatHours(date) {
        var minute = date.getMinutes();
        if(minute < 10){
          minute = '0'+minute
        }
        var hour = date.getHours();
        return  hour + ':' +minute ;
      },
      loadTooltip(){
        const self = this;
        var app = self.$f7;
        setTimeout(function(){
          var navbarTooltip = app.tooltip.create({
            targetEl: '.icon-tooltip-required',
            text: self.$root.setLang('required')
          });
          var minutesTooltip = app.tooltip.create({
            targetEl: '.icon-tooltip-minutes',
            text: self.$root.setLang('minutes_done')
          });
          var liveminutesTooltip = app.tooltip.create({
            targetEl: '.icon-tooltip-live-minutes',
            text: self.$root.setLang('live_minutes_done')
          });
          var text_chat_with_manager = self.$root.setLang('chat_with_manager');
          if(self.$f7.data.user.roles.indexOf('admin') > -1){
            text_chat_with_manager = self.$root.setLang('chat_with_client');
          }
          var chatmanagerTooltip = app.tooltip.create({
            targetEl: '.icon-tooltip-chat-with-manager',
            text: text_chat_with_manager
          });
          var chatmanagerdoneTooltip = app.tooltip.create({
            targetEl: '.icon-tooltip-chat-with-manager-done',
            text: self.$root.setLang('chat_with_manager_done')
          });
          var chatmanagernewTooltip = app.tooltip.create({
            targetEl: '.icon-tooltip-chat-with-manager-new',
            text: self.$root.setLang('chat_with_manager_new')
          });

        }, 1000)
      },
      loadMore() {
        const self = this;
        if(self.showPreloader && !self.is_loading){
          self.is_loading = true;
          if (!self.allowInfinite) return;
          self.allowInfinite = false;
            if (self.orders.length >= 5000) {
              self.showPreloader = false;
              return;
            }
            // self.loadData()
            self.loadMoreVideovieworders(self.page, function(orders){
              if(orders){
                if(orders.length == 0){
                  self.showPreloader = false;
                }
                self.$f7.data.videovieworders = self.$f7.data.videovieworders.concat(orders);
                self.loadTooltip();
                // self.orders = self.orders.concat(orders);
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
            
        }
      },
      // loadMore() {
      //   const self = this;
      //   if(self.showPreloader && !self.is_loading){
      //     self.is_loading = true;
      //     if (!self.allowInfinite) return;
      //     self.allowInfinite = false;
      //     // setTimeout(() => {
      //       if (self.orders.length >= 400) {
      //         self.showPreloader = false;
      //         return;
      //       }

      //       // const itemsLength = self.items.length;

      //       // for (let i = 1; i <= 20; i += 1) {
      //       //   self.items.push(itemsLength + i);
      //       // }
      //       self.loadData()
      //       self.allowInfinite = true;
      //     // }, 1000);
      //   }
      // },
      loadRefresh(done) {
        const self = this;
        self.orders = []
        self.is_loading = true;
        self.showPreloader = true;
        self.page = 1;
        // self.loadData();
        // done();
        self.loadMoreVideovieworders(self.page, function(orders){
              if(orders){
                if(orders.length == 0){
                  self.showPreloader = false;
                }
                // self.$f7.data.orders = self.$f7.data.orders.concat(orders);
                // self.orders = self.orders.concat(orders);
                self.$f7.data.videovieworders = orders;
                self.loadTooltip();
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
              if(typeof done === "function"){
                done();
              }
            })
      },
      loadMoreVideovieworders(page, callback) {
        const self = this;
        var app = self.$f7;
        var service_ids = self.$root.services.join(',');
        if(self.service_selected != "*"){
          service_ids = self.service_selected;
        }

        var data_post = {action: 'tiktokliveorderslist', supplier: self.$root.supplier, token: app.data.user.token, service_ids: service_ids,page: page, key: self.key,status: self.status,datebegin: self.datebegin,dateend: self.dateend};
        if(self.$root.service_alias == "live_vip"){
          data_post.is_vip = "yes";
        }
        if(self.show_new_chat){
          if(self.$f7.data.user.roles.indexOf('admin') > -1){
            data_post.notification_user = 2;
          }else{
            data_post.notification_admin = 2;
          }
        }
        if(self.show_have_chat){
          if(self.$f7.data.user.roles.indexOf('admin') > -1){
            data_post.notification_user = 1;
          }else{
            data_post.notification_admin = 1;
          }
        }
        if(self.not_enough_viewers){
          data_post.not_enough = 1;
        }
        app.request.post(self.$root.$f7.params.apiurl, data_post, function(data){
          var orders = data.data;
          self.total_order = data.count;
          callback(orders);
        },
        function(err){
          callback(false);
        },'json');
      },
      statusOrder(status){
        const self = this;
        // var list_status = ['Add','Active', 'Running', 'Completed','Cancelled','','Req Cancel','Pause'];
        var list_status = [self.$root.setLang('status_1'),self.$root.setLang('status_2'),self.$root.setLang('status_3'),self.$root.setLang('status_4'),self.$root.setLang('status_5'),'',self.$root.setLang('status_7'),self.$root.setLang('status_8')];
        if(list_status[status-1]){
          return list_status[status-1];
        }
        return "";
      },

      getCurentViewer(order){
        var viewer = "...";
        if(order.last_viewer >= 0){
          viewer = order.last_viewer;
        }
        return viewer
      },
      checkViewer(order){
        var is_enough = false;
        var viewer = order.last_viewer;
        // try{
        //   var log_v4 = JSON.parse("["+order.log_v4+"]");
        //   viewer = log_v4[log_v4.length-1].viewer;
        // }catch(e){}
        if(viewer >= (order.amount+order.start_count)){
          is_enough = true
        }
        return is_enough
      },
      statusOrderNew(order){
        const self = this;
        // var list_status = ['Add','Active', 'Running', 'Completed','Cancelled','','Req Cancel','Pause'];
        var list_status = [self.$root.setLang('status_1'),self.$root.setLang('status_2'),self.$root.setLang('status_3'),self.$root.setLang('status_4'),self.$root.setLang('status_5'),'',self.$root.setLang('status_7'),self.$root.setLang('status_8')];
        var order_status = order.status-1;
        if(order.status == 8 && order.service_id.id == 68 && order.minutes_view_count > 600000){
          order_status = 3;
        }
        if(order.status == 8 && order.service_id.id == 60 && order.minutes_view_count > 60000){
          order_status = 3;
        }
        if(list_status[order_status]){
          return list_status[order_status];
        }
        return "";
      },
      statusOrderColor(order){
        // var list_status = ['Add','Active', 'Running', 'Completed','Cancelled','','Req Cancel','Pause'];
        var list_status = ['grey','lightblue', 'blue', 'green','red','','orange','purple'];
        var order_status = order.status-1;
        if(order.status == 8 && order.service_id.id == 68 && order.minutes_view_count > 600000){
          order_status = 3;
        }
        if(order.status == 8 && order.service_id.id == 60 && order.minutes_view_count > 60000){
          order_status = 3;
        }
        if(list_status[order_status]){
          return list_status[order_status];
        }
        return "";
      },
      more(order) {
        const self = this;
        self.order_swipe = order;
        self.onPageInit();
        self.actions.open();
      },
      openLink(order) {
        const self = this;
        self.$f7router.navigate('/videovieworders/'+order._id, { transition: 'f7-cover' })
      },
      doOrder(order, handle) {
        const self = this;
        var app = self.$f7;
        app.dialog.progress(self.$root.setLang('request_sending'));
        app.request.post(self.$root.$f7.params.apiurl, {action: 'dolivetiktokorder', handle: handle, id: order._id, supplier: self.$root.supplier, token: app.data.user.token}, function(data){
          if(data && data.status == "success"){
            self.loadRefresh(true)
          }
          setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert(self.$root.setLang('request_had_been_success'), self.$root.setLang('success'));
              }else{
                app.dialog.alert(data.message, self.$root.setLang('request_had_been_failed'));
              }
            }, 500);
        },
        function(err){
          try{
            try{
              app.dialog.alert(JSON.parse(err.response).message, self.$root.setLang('request_had_been_failed'));
            }catch(e){
              app.dialog.alert(err.response);
            }
            
          }catch(e){
            app.dialog.alert(err);
          }
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
        },'json');
      },
      changeAccesstoken(order, handle) {
        const self = this;
        var app = self.$f7;
        var mycheck;
        var dialog = app.dialog.create({
            title: self.$root.setLang('change_accesstoken'),
            text: self.$root.setLang('get_accesstoken_guide'),
            content: `<div class="list dialog-input-field item-input"><div class="item-input-wrap"><textarea class="resizable dialog-input" name="accesstoken" id="accesstoken" placeholder="EAAGNO....." required style="max-height:300px;"></textarea>
            <div class="row"><div class="col"><a href="${self.$root.setLang('get_accesstoken_guide_url')}" target="_blank" class="button link external" style="font-size: 13px; display: inline-block; border: 1px solid rgb(227, 227, 227); background-color: rgb(255, 255, 255);">${self.$root.setLang('go_to_get_access_token')}</a></div></div>

            </div></div>`,
            buttons: [{text:self.$root.setLang('cancel'),close:true},{text: self.$root.setLang('console')},{text: self.$root.setLang('send')}],
            onClick: function (dialog, index) {
              var accesstoken_value = dialog.$el.find('#accesstoken').val();
              var accesstoken = self.$root.getAccesstokenFromHtml(accesstoken_value);
              var accesstoken_check = false;
              if(accesstoken && (accesstoken.indexOf('EAAGNO') > -1 || accesstoken.indexOf('EAAGNO') > -1) && accesstoken.indexOf('"') == -1 && accesstoken.indexOf(',') == -1 && accesstoken.indexOf(' ') == -1 && accesstoken.length > 50 && accesstoken.length < 300){
               accesstoken_check = true;
              }
              if(index == 0){
                dialog.close();
              }else if(index == 1){
                // self.$copyText(self.$root.setLang('js_access_token')).then(self.$root.onCopySuccess, self.$root.onCopyError);
                // dialog.open();
                self.$copyText(self.$root.setLang('js_access_token')).then(function(el){
                  var dialog1 = self.$f7.dialog.create({
                    title: self.$root.setLang('copied'),
                    text: el.text, 
                    animate: true,
                    on: {
                      opened: function () {
                        // console.log('Dialog opened')
                      }
                    }
                  })
                  dialog1.open()
                  
                  setTimeout(function(){
                    dialog1.close();
                    dialog.open()
                  }, 1000)
                }, function(e){
                  var dialog1 = self.$f7.dialog.create({
                    title: self.$root.setLang('error'),
                    text: self.$root.setLang('can_not_copy'), 
                    animate: true,
                    on: {
                      opened: function () {
                        // console.log('Dialog opened')
                      }
                    }
                  })
                  dialog1.open()
                  setTimeout(function(){
                    dialog1.close();
                    dialog.open()
                  }, 1000)
                });
              }else if(!accesstoken_check){
                // dialog.close();
                var token_dialog = app.dialog.alert(self.$root.setLang('accesstoken_wrong'), self.$root.setLang('failed'));
                // self.$f7.dialog.close();
                // console.log(dialog.$el.find('#accesstoken').val());
              }else{
                app.dialog.progress(self.$root.setLang('accesstoken_updating'));
                app.request.post(self.$root.$f7.params.apiurl, {action: 'dolivetiktokorder', handle: handle, id: order._id, accesstoken: accesstoken,supplier: self.$root.supplier, token: app.data.user.token}, function(data){
                  if(data && data.status == "success"){
                  }
                  setTimeout(() => {
                      self.$f7.dialog.close();

                      if(data && data.status == "success"){
                        app.dialog.alert(self.$root.setLang('accesstoken_updated'), self.$root.setLang('success'));
                      }else{
                        app.dialog.alert(data.message, self.$root.setLang('request_had_been_failed'));
                      }
                    }, 500);
                },
                function(err){
                  try{
                    try{
                      app.dialog.alert(JSON.parse(err.response).message, self.$root.setLang('request_had_been_failed'));
                    }catch(e){
                      app.dialog.alert(err.response);
                    }
                    
                  }catch(e){
                    app.dialog.alert(err);
                  }
                    setTimeout(() => {
                      self.$f7.dialog.close();
                    }, 500);
                },'json');
              }
            },
            on: {
                open: function () {
                    // console.log("OPEN");
                    mycheck = setInterval(checkInput, 1000);
                    function checkInput(){
                      var accesstoken_value = dialog.$el.find('#accesstoken').val();
                      if(accesstoken_value != ""){
                        var accesstoken = self.$root.getAccesstokenFromHtml(accesstoken_value);
                        if(accesstoken_value != accesstoken){
                          dialog.$el.find('#accesstoken').val(accesstoken)
                          // console.log(accesstoken)
                        }
                      }
                    }
                    
                },
                close: function () {
                    // console.log("CLOSE");
                      clearInterval(mycheck)
                }
            }
        }).open();
      },
      autoRefresh(){
        const self = this;
        if(self.page == 2){
          self.orders = []
        self.is_loading = true;
        self.showPreloader = true;
        self.page = 1;
        self.loadMoreVideovieworders(self.page, function(orders){
              if(orders){
                if(orders.length == 0){
                  self.showPreloader = false;
                }
                self.$f7.data.videovieworders = orders;
                self.loadTooltip();
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
                if(self.$f7.data.videovieworders.length > 0 && self.page ==1){
                  self.page++;
                }
                self.loadTooltip();
              }
              self.allowInfinite = true;
              setTimeout(self.autoRefresh, 30000);
            })
        }else{
          setTimeout(self.autoRefresh, 10000);
        }
      },
      bupSupport(order, handle) {
        const self = this;
        var app = self.$f7;
        var amount = order.amount+order.start_count-order.last_viewer+4;
        if(amount < 10){
          amount = 10;
        }
        if(amount > order.amount){
          amount = order.amount;
        }
        var text = '';
        if(order.last_viewer == -1){
          text = self.$root.setLang('still_not_get_viewers');
        }else{
          text = `${order.last_viewer} ${self.$root.setLang('viewers')} ${self.$root.setLang('at')} ${self.formatHours(new Date(order.last_viewer_time))}`;
        }
        var dialog = app.dialog.create({
            title: order.video_id,
            // text: self.$root.setLang('amount_viewer_not_enough')+self.$root.setLang('live_view_amount_min'),
            text: text,
            content: `<div class="list dialog-input-field item-input" style="margin-bottom:0;text-align: initial;">
              <div class="row">
                <div class="col">
                  ${self.$root.setLang('amount_viewer_not_enough')+self.$root.setLang('live_view_amount_min')}
                </div>
              </div>
              <div class="item-input-wrap" style="margin-top: 5px;">
                <div class="item-title item-label">${self.$root.setLang('amount_viewer_more')}</div>
                <input type="number" name="amount" id="amount" value="${amount}" required />
              </div>
            </div>`,
            buttons: [{text:self.$root.setLang('cancel'),close:true},{text: self.$root.setLang('send')}],
            onClick: function (dialog, index) {
              amount = dialog.$el.find('#amount').val();
              if(index == 0){
                // console.log({action: 'order', service_id: order.service_id,orders: [{url: order.video_id, amount: amount, num_minutes: (order.num_minutes - parseInt((Date.now()/1000-order.start_time)/60)), note: order._id}], supplier: self.$root.supplier, token: app.data.user.token})
                dialog.close();
              }else{
                app.dialog.progress(self.$root.setLang('ordering'));
                var num_minutes = (order.num_minutes - parseInt((Date.now()/1000-order.start_time)/60));
                if(num_minutes < 30){
                  num_minutes = 30;
                }
                var data_post = {
                  action: 'order', service_id: order.service_id,orders: [{url: order.url, amount: amount, num_minutes: num_minutes, note: order._id}], supplier: self.$root.supplier, token: app.data.user.token
                }
                if(order.is_vip){
                  data_post.is_vip = "yes";
                }
                app.request.post(self.$root.$f7.params.apiurl, data_post, function(data){
                  if(data && data.status == "success"){
                  }
                  setTimeout(() => {
                      self.$f7.dialog.close();

                      if(data && data.status == "success"){
                        app.dialog.alert(self.$root.setLang('success'), self.$root.setLang('success'));
                        setTimeout(() => {
                          app.dialog.close();
                        }, 500);
                      }else{
                        app.dialog.alert(data.message, self.$root.setLang('failed'));
                      }
                    }, 500);
                },
                function(err){
                  try{
                    try{
                      app.dialog.alert(JSON.parse(err.response).message, self.$root.setLang('failed'));
                    }catch(e){
                      app.dialog.alert(err.response);
                    }
                    
                  }catch(e){
                    app.dialog.alert(err);
                  }
                    setTimeout(() => {
                      self.$f7.dialog.close();
                    }, 500);
                },'json');
              }
            },
            on: {
                open: function () {
                },
                close: function () {
                }
            }
        }).open();
      },
      onPageInit() {
        const self = this;
        // self.loadData()
        const app = self.$f7;
        self.loadBalances();
        var filter_json = app.form.getFormData('filter_json');
        var need_refresh = false;
        if(filter_json){
          self.filter_json = filter_json;
          if(typeof self.filter_json.show_filter !== 'undefined'){
            self.show_filter = self.filter_json.show_filter;
          }
          if(typeof self.filter_json.show_have_chat !== 'undefined'){
            self.show_have_chat = self.filter_json.show_have_chat;
            need_refresh = true;
          }
          if(typeof self.filter_json.show_new_chat !== 'undefined'){
            self.show_new_chat = self.filter_json.show_new_chat;
            need_refresh = true;
          }
          if(typeof self.filter_json.not_enough_viewers !== 'undefined'){
            self.not_enough_viewers = self.filter_json.not_enough_viewers;
            need_refresh = true;
          }
          if(typeof self.filter_json.status !== 'undefined'){
            self.status = self.filter_json.status;
            need_refresh = true;
          }
          if(typeof self.filter_json.key !== 'undefined'){
            self.key = self.filter_json.key;
            need_refresh = true;
          }
        }
        if(need_refresh){
          self.loadRefresh();
        }
        // console.log(self.filter_json);
        // console.log('app', self)
        // self.$root.aNewFunction()
        // self.$root.$options.components.app.methods.newPageOpen()
        // console.log(self.$root.$options.components.app.methods)
        // app.methods.newPageOpen()
        // self.$root.otherpage.mainFunc
        self.actions = app.actions.create({
          buttons: [
            [
              {
                text: self.$root.setLang('select_action_for_order'),
                label: true,
              },
              {
                text: self.$root.setLang('copy_url'),
                onClick: function () {
                  self.$root.copyText(self.order_swipe.url)
                }
              },
              {
                text: self.$root.setLang('copy_id'),
                onClick: function () {
                  self.$root.copyText(self.order_swipe._id)
                }
              },
              {
                text: self.$root.setLang('copy_id_video'),
                onClick: function () {
                  if(self.order_swipe.video_id != "" && self.order_swipe.video_id != "0"){
                    self.$root.copyText(self.order_swipe.video_id)
                  }else{
                    app.dialog.alert(self.$root.setLang('order_not_active'),self.$root.setLang('failed'))
                  }
                }
              },
              // {
              //   text: self.$root.setLang('change_accesstoken'),
              //   onClick: function () {
              //     if((self.order_swipe.service_id.id == 60 || self.order_swipe.service_id.id == 68 || self.order_swipe.service_id.id == 111) && (self.order_swipe.status == 1 || self.order_swipe.status == 2 || self.order_swipe.status == 3 || self.order_swipe.status == 8)){
              //       // app.dialog.alert('Ok',self.order_swipe._id)
              //       // self.doOrder(self.order_swipe, "changetoken");
              //       self.changeAccesstoken(self.order_swipe,"changetoken");
              //     }else{
              //       app.dialog.alert(self.$root.setLang('can_not_change_this_order'),self.$root.setLang('failed'))
              //     }
              //   }
              // },
              {
                text: self.$root.setLang('stop_and_refund'),
                onClick: function () {
                  if([3,8,1,2].indexOf(self.order_swipe.status) >-1){
                    // app.dialog.alert('Ok',self.order_swipe._id)
                    self.doOrder(self.order_swipe, "stop");
                  }else{
                    app.dialog.alert(self.$root.setLang('can_not_stop_this_order')+' "'+self.statusOrder(self.order_swipe.status)+'"',self.$root.setLang('failed'))
                  }
                }
              },
              {
                text: self.$root.setLang('pause'),
                onClick: function () {
                  if(self.order_swipe.status == 3){
                    // app.dialog.alert('Ok',self.order_swipe._id)
                    self.doOrder(self.order_swipe, "pause");
                  }else{
                    app.dialog.alert(self.$root.setLang('can_not_pause_this_order')+' "'+self.statusOrder(self.order_swipe.status)+'"',self.$root.setLang('failed'))
                  }
                }
              },
              {
                text: self.$root.setLang('resume'),
                onClick: function () {
                  if(self.order_swipe.status == 8){
                    // app.dialog.alert('Ok',self.order_swipe._id)
                    self.doOrder(self.order_swipe, "resume");
                  }else{
                    app.dialog.alert(self.$root.setLang('can_not_resume_this_order')+' "'+self.statusOrder(self.order_swipe.status)+'"',self.$root.setLang('failed'))
                  }
                }
              },
              // {
              //   text: 'Chạy lại đơn đã hủy',
              //   onClick: function () {
              //     if(self.order_swipe.status == 5){
              //       // app.dialog.alert('Ok',self.order_swipe._id)
              //       self.doOrder(self.order_swipe, "restart");
              //     }else{
              //       app.dialog.alert('Không thể tạm dừng đơn hàng "'+self.statusOrder(self.order_swipe.status)+'"','Thất bại!')
              //     }
              //   }
              // },
              {
                text: self.$root.setLang('request_cancel'),
                onClick: function () {
                  if(self.order_swipe.status == 4){
                    // app.dialog.alert('Ok',self.order_swipe._id)
                    self.doOrder(self.order_swipe, "reqcancel");
                  }else{
                    app.dialog.alert(self.$root.setLang('can_not_request_cancel_this_order')+' "'+self.statusOrder(self.order_swipe.status)+'"',self.$root.setLang('failed'))
                  }
                }
              },
            ],
            [
              {
                text: self.$root.setLang('cancel'),
                color: 'red'
              },
            ],
          ],
        });
      },
    },
    updated() {
      const self = this;
      const app = self.$f7;
      if(!self.started_page){
        self.started_page = true;
        self.autoRefresh();
        // console.log("ok",self.is_loading)
        var navbarTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-required',
          text: self.$root.setLang('required')
        });
        var minutesTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-minutes',
          text: self.$root.setLang('minutes_done')
        });
        var liveminutesTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-live-minutes',
          text: self.$root.setLang('live_minutes_done')
        });
      }
    },
    mounted() {
    },
  };
</script>