var site = {
	name: "Watch Time Facebook",
	supplier: '5c46edc1fdb2e5227423ddd1',
	lang_default: 'vn',
	managerSite: 'https://viewadbreaks38.com/',
  	service_alias: 'minutes',
	services: ["60487de89f59b726488ebec3","5e0c5e84fda7621b5db9cd2b","6058ad61163ee61790e3143d","62f9deeb23e90ce94a0b2ba7","63a995bc60b0b575377a7e81",
	  "63c409744243de38e2f4d124",//follow class
	  "63c7a20b4243de38e2f627e1",//follow pro5
  "63e645ec5df0bfcbd0c47c6a",//60k minutes
  "63cfeda22198f0df3483a880",//reaction
	  ],
  	paymentData: {
        'Vietcombank' : {
			'status': true,
			'selected': true,//set default show
			'id': '0201000664552',
			'name': 'Le Phi Long',
			'bank_name': 'Vietcombank',
			'min': 2.5,
			'auto': true,
        },
    },
    contacts: {
        'phone': {
            'id': '+84356810777',
            'name': 'Phone, Zalo, WhatsApp, Telegram:',
        },
        'facebook': {
            'id': 'https://www.facebook.com/sink.tc.5',
            'name': 'Facebook:',
              
        },
    },
}
export default site;